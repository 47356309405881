import React from 'react'
import { NavLink } from 'react-router-dom'
// 
import truckcourosel from '../assets/truckcourosel.webp'
import slidetaxi from '../assets/carcourosel.webp'
import slidetyre from '../assets/slidetyre.webp'
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import rightarrow from '../assets/right-arrow.svg'
// animate css
import 'animate.css';




function Couroselhome() {

    return (
        <>
            <div className="container-fluid flexcenter p-0">
                <OwlCarousel
                    className="owl-theme topowl"
                    dots={true}
                    nav={false}
                    items={1}
                    loop={true}
                    autoplay={true}
                    autoplayTimeout={5000}
                    autoplayHoverPause={false}
                    // animateIn='animate__slideInRight'
                    animateOut='animate__fadeOutLeft'
                 
                >
                    <div className="item w-100">
                        <div className="item-outer w-100">
                            <div className="flexcenter">
                                <img className="img" src={truckcourosel} alt="" />
                            </div>

                            <div className="item-inner flexcenter">
                                <div className="row w-100">
                                    <div className="col-md-8 couroseltextctn flexcenter">
                                        <div className="container">

                                            <h1 className='couroseltitle'>Haulage Services</h1>

                                            <h5 className='couroselsub'>Fast and Reliable Service</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4 flexcenter">
                                        <NavLink to='/services/haulageservices'>
                                            <img className="rightarrowimg" src={rightarrow} alt="" />
                                        </NavLink>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="item w-100">
                        <div className="item-outer flexcenter w-100">
                            <div className="flexcenter">
                                <img className="img" src={slidetaxi} alt="" />
                            </div>
                            <div className="item-inner flexcenter">
                                <div className="row w-100">
                                    <div className="col-md-8 couroseltextctn flexcenter">
                                        <div className="container">
                                            <h1 className='couroseltitle'>Taxi Services</h1>
                                            <h5 className='couroselsub'>24/7 Availability</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4 flexcenter">
                                        <NavLink to='/services/taxiservice'>
                                            <img className="rightarrowimg" src={rightarrow} alt="" />
                                        </NavLink>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item w-100">
                        <div className="item-outer w-100">
                            <div className="flexcenter">
                                <img className="img" src={slidetyre} alt="" />
                            </div>
                            <div className="item-inner flexcenter">
                                <div className="row w-100">
                                    <div className="col-md-8 couroseltextctn flexcenter">
                                        <div className="container">
                                            <h1 className='couroseltitle'>Tyre Distributor</h1>
                                            <h5 className='couroselsub'>Quality Brands & Wide Selection</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4 flexcenter">
                                        <NavLink to='/services/tyredistributor'>
                                            <img className="rightarrowimg" src={rightarrow} alt="" />
                                        </NavLink>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='scrolldown flexcenter'>
                        <div>
                            <p className='mb-2'>Scroll Down</p>
                            <div className="flexcenter">
                                <img width='20px' className='arrowimage' src={scrolldownicon} alt="" />
                            </div>
                        </div>
                    </div> */}
                </OwlCarousel>
            </div>

        </>
    )
}

export default Couroselhome