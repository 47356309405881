import * as yup from 'yup'
import 'yup-phone'

export const formSchema = yup.object().shape({
    "name": yup.string("Enter only string").matches(/^[a-zA-Z\s]+$/, 'Only characters').required("Required field"),
    "email": yup.string().email("Enter a valid email").required("Required field"),
    "phone": yup.string().matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "Enter number only"
      ).required("Required field"),
    // "phone": yup.number("Enter number only").integer("Enter number only").required("Required field"),
    "message": yup.string().max(50,'Message should be less than 50 words').required("Required field")
})