import React, { useEffect } from 'react'
// 
import scrolldownicon from '../assets/scroll-down-icon.png'
import haulagetruck from '../assets/haulagetruck.png'
import truckstype from '../assets/truckstype.png'
import convenience from '../assets/convenience.png'
import navigation from '../assets/navigation.png'
import team from '../assets/team.png'
import flexible from '../assets/flexible.png'
import coverage from '../assets/coverage.png'



// 
import { Helmet } from 'react-helmet'
// 
import { useLocation } from 'react-router-dom'


function Deliverypage() {
    const { pathname } = useLocation()
    useEffect(() => {
        window.scrollTo({ top:0, left:0, behavior: "instant" });
    }, [pathname])
    return (
        <>
            <Helmet>
                <title>Trident - Haulage Services</title>
                <meta property="og:title" content="Trident - Haulage Services" />
                <meta property="og:description" content="Trident offers reliable and innovative haulage, taxi, and tyre solutions with a customer-first approach, ensuring smooth journeys and satisfaction." />
                {/* <meta property="og:image" content={logoblue}/> */}
                <meta property="og:url" content="https://thetrident.co.uk/services/haulageservices" />
            </Helmet>
            <section className='contacttopsection flexcenter'>
                <div className="container-fluid  p-0">
                    <div className="container flexcenter">
                        <div className="row w-100">
                            <div className="col-md-6 flexcenter" data-aos="fade-right" data-aos-easing="ease-in-sine"
                                data-aos-duration="500">
                                <img className='img-fluid' width="600px" src={haulagetruck} alt="" />
                            </div>
                            <div className="col-md-6 flexcenter" data-aos="fade-left" data-aos-easing="ease-in-sine"
                                data-aos-duration="500">
                                <div className="container">
                                    <h1>Haulage Services</h1>
                                    <p>We prioritize on-time delivery to meet your deadlines, offering reliable and secure haulage solutions, a convenient booking process with flexible options, and advanced tracking systems for real-time updates.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='scrolldown flexcenter'>
                        <div>
                            {/* <p className='mb-2'>Scroll Down</p> */}
                            <div className="flexcenter">
                                <img width='20px' className='arrowimage' src={scrolldownicon} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='sericespagecontain flexcenter'>
                <div className="container-fluid pt-5 pb-5">
                    <div className="container">
                        <h1 className='headtext text-center mb-5' data-aos="fade-up" data-aos-easing="ease-in-sine"
                            data-aos-duration="500">Haulage Services</h1>
                        <p style={{ textAlign: "justify" }} data-aos="fade-up" data-aos-easing="ease-in-sine"
                            data-aos-duration="800">
                            At Trident, we offer reliable and efficient haulage services to meet all your transportation needs. With a commitment to safety, speed, and professionalism, we ensure that your goods are transported securely and on time, every time.
                        </p>
                    </div>
                </div>
            </section>

            <section className='sectionheight flexcenter'>
                <div className="container-fluid p-0">
                    <h1 className='headtext text-center mt-5 ms-5 me-5' data-aos="fade-up" data-aos-easing="ease-in-sine"
                        data-aos-duration="500">Why Choose Our Haulage Services?</h1>
                    <div className="container mt-5 flexcenter">
                        <div className="row w-100 flexcenter">
                            <div className="col-md-6">
                                <div>
                                    <div data-aos="fade-right" data-aos-easing="ease-in-sine"
                                        data-aos-duration="500">
                                        <div className="serviceftrctn flexcenter">
                                            <div className="row w-100">
                                                <div className="col-4 flexcenter">
                                                    <img width='200px' className='img-fluid' src={truckstype} alt="" />
                                                </div>
                                                <div className="col-8 flexcenter">
                                                    <div className="container">
                                                        <h3 style={{ fontSize: '24px' }}>Comprehensive Fleet</h3>
                                                        <p>We operate a diverse range of vehicles, from small trucks to heavy-duty lorries, capable of transporting all types of cargo, including bulk, palletized, and oversized items.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-aos="fade-right" data-aos-easing="ease-in-sine"
                                        data-aos-duration="800">
                                        <div className="serviceftrctn flexcenter">
                                            <div className="row w-100">
                                                <div className="col-4 flexcenter">
                                                    <img width='150px' className='img-fluid' src={coverage} alt="" />
                                                </div>
                                                <div className="col-8 flexcenter">
                                                    <div className="container">
                                                        <h3 style={{ fontSize: '24px' }}>Nationwide Coverage</h3>
                                                        <p>We cover a wide geographic area, ensuring your goods are transported to their destination efficiently, no matter the distance.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div data-aos="fade-right" data-aos-easing="ease-in-sine"
                                        data-aos-duration="1000">
                                        <div className="serviceftrctn flexcenter">
                                            <div className="row w-100">
                                                <div className="col-4 flexcenter">
                                                    <img width='150px' className='img-fluid' src={navigation} alt="" />
                                                </div>
                                                <div className="col-8 flexcenter">
                                                    <div className="container">
                                                        <h3 style={{ fontSize: '24px' }}>Real-Time Tracking</h3>
                                                        <p>Stay updated on your shipment's progress with our advanced tracking technology, providing you with peace of mind and the ability to monitor your deliveries in real time.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="container">

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <div data-aos="fade-left" data-aos-easing="ease-in-sine"
                                        data-aos-duration="500">
                                        <div className="serviceftrctn flexcenter">
                                            <div className="row w-100">
                                                <div className="col-4 flexcenter">
                                                    <img width='200px' className='img-fluid' src={convenience} alt="" />
                                                </div>
                                                <div className="col-8 flexcenter">
                                                    <div className="container">
                                                        <h3 style={{ fontSize: '24px' }}>Specialized Solutions</h3>
                                                        <p>Whether you need refrigerated transport for perishable goods, container haulage, or bulk transportation of raw materials, we have tailored solutions for every industry.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-aos="fade-left" data-aos-easing="ease-in-sine"
                                        data-aos-duration="800">
                                        <div className="serviceftrctn flexcenter">
                                            <div className="row w-100">
                                                <div className="col-4 flexcenter">
                                                    <img width='150px' className='img-fluid' src={team} alt="" />
                                                </div>
                                                <div className="col-8 flexcenter">
                                                    <div className="container">
                                                        <h3>Experienced Team</h3>
                                                        <p>Our team of skilled drivers and logistics experts ensures smooth coordination, timely deliveries, and compliance with all safety regulations.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div data-aos="fade-left" data-aos-easing="ease-in-sine"
                                        data-aos-duration="1000">
                                        <div className="serviceftrctn flexcenter">
                                            <div className="row w-100">
                                                <div className="col-4 flexcenter">
                                                    <img width='150px' className='img-fluid' src={flexible} alt="" />
                                                </div>
                                                <div className="col-8 flexcenter">
                                                    <div className="container">
                                                        <h3>Flexible Services</h3>
                                                        <p>We offer both full-load and part-load haulage options to meet your specific requirements, ensuring cost-effectiveness and efficiency.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="container">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section>
                <h1 className='headtext text-center mt-5 mb-5' data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine">Industries We Serve</h1>
                <div className="container-fluid flexcenter p-0">
                    <div className="container flexcenter mb-2">
                        <div className="row w-100">
                            <div className="col-md-3 mb-2" data-aos="fade-left" data-aos-duration="500" data-aos-easing="ease-in-sine">
                                <div className="haulageconatin flexcenter">
                                    <h5>1. Retail and E-commerce</h5>
                                </div>
                            </div>
                             <div className="col-md-3 mb-2"  data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-in-sine">
                                <div className="haulageconatin flexcenter">
                                    <h5>2. Manufacturing and Construction</h5>
                                </div>
                            </div>
                            <div className="col-md-3 mb-2"  data-aos="fade-left" data-aos-duration="1000" data-aos-easing="ease-in-sine">
                                <div className="haulageconatin flexcenter">
                                    <h5>3. Food and Beverage</h5>
                                </div>
                            </div>
                            <div className="col-md-3 mb-2"  data-aos="fade-left" data-aos-duration="1200" data-aos-easing="ease-in-sine">
                                <div className="haulageconatin flexcenter">
                                    <h5>4. Automotive and Machinery</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="container">
                <p className='text-center mt-2 mb-4' style={{ textAlign: "justify",fontWeight:"bold" ,fontSize:'18px'}} data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine">
                From small deliveries to complex logistics projects, Trident is your trusted partner for all your haulage needs. Contact us today to discuss how we can help streamline your supply chain.
            </p>
                </div>
            </section>


        </>
    )
}

export default Deliverypage