import React,{useEffect} from 'react'
// 
import scrolldownicon from '../assets/scroll-down-icon.png'
import taxi from "../assets/abouttaxiimg.png";
import truckabout from "../assets/truckabout.png";
import tyre from "../assets/tyresaleimg.png";
// 
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
// 
import { useLocation } from 'react-router-dom';


function About() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top:0, left:0, behavior: "instant" });
  }, [pathname]);
  return (
    <>
      <Helmet>
        <title>Trident - About</title>
        <meta property="og:title" content="Trident - About" />
        <meta property="og:description" content="Trident offers reliable and innovative haulage, taxi, and tire solutions with a customer-first approach, ensuring smooth journeys and satisfaction." />
        {/* <meta property="og:image" content={logoblue}/> */}
        <meta property="og:url" content="https://thetrident.co.uk/about" />
      </Helmet>
      <section className='contacttopsection flexcenter'>
        <div className="container-fluid p-0">
          <div className="container">
            <h1 className="text-center" data-aos="fade-up" data-aos-easing="ease-in-sine"
              data-aos-duration="500">Who We Are</h1>
            <p className='text-justify mt-5' data-aos="fade-up" data-aos-easing="ease-in-sine"
              data-aos-duration="800">At Trident, we are committed to delivering exceptional service across our haulage, taxi, and tyre solutions, combining reliability, innovation, and a passion for excellence to ensure every journey is smooth and every customer is satisfied. With a dedicated team of professionals and a customer-first approach, we strive to be your trusted partner in every aspect of your transportation and vehicle care needs.</p>
          </div>
          <div className='scrolldown flexcenter'>
            <div>
              {/* <p className='mb-2'>Scroll Down</p> */}
              <div className="flexcenter">
                <img width='20px' className='arrowimage' src={scrolldownicon} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="homebgcolor">
        <h1 className="headtext text-center pt-5" data-aos="fade-up" data-aos-easing="ease-in-sine"
          data-aos-duration="500">Our Services</h1>
        <section>
          <div className="container-fluid p-0 flexcenter">
            <div className="container homecard flexcenter">
              <div className="row">
                <div className="col-md-6 flexcenter" data-aos="fade-right" data-aos-easing="ease-in-sine"
                  data-aos-duration="500">
                  <img
                    width="600px"
                    className="img-fluid"
                    src={truckabout}
                    alt=""
                  />
                </div>
                <div className="col-md-6 homecardinside flexcenter" data-aos="fade-left" data-aos-easing="ease-in-sine"
                  data-aos-duration="500">
                  <div className="container homecardwrapper text-center">
                    <h1>Haulage Services</h1>
                    <p>At Trident, we understand the importance of reliable and timely haulage. Our haulage service is designed to ensure that your shipments arrive safely and promptly, no matter where they need to go. Whether you’re transporting a small load or a large consignment, our dedicated team uses state-of-the-art logistics technology to track and manage your haulage efficiently. We pride ourselves on our commitment to customer satisfaction and our ability to meet the diverse needs of our clients. Trust us to deliver excellence every time.</p>
                    <div className="flexcenter">
                      <NavLink to='/services/haulageservices'><button className="button2">Read More</button></NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* taxi servive */}
        <section>
          <div className="container-fluid p-0 flexcenter">
            <div className="container homecard homecardflipped flexcenter">
              <div className="row reversecolumn">
                <div className="col-md-6 homecardinside flexcenter" data-aos="fade-right" data-aos-easing="ease-in-sine"
                  data-aos-duration="500">
                  <div className="container text-center">
                    <h1>Taxi Services</h1>

                    <p>
                      Our taxi service at Trident is all about convenience, comfort, and reliability. We offer a fleet of well-maintained vehicles driven by professional and courteous drivers who prioritize your safety and comfort. Whether you need a ride to the airport, a trip across town, or a chauffeur for a special event, we’ve got you covered. With our easy-to-use booking system and 24/7 availability, getting a taxi has never been simpler. Experience a hassle-free journey with us and see why we are the preferred choice for transportation.                    </p>
                    <div className="flexcenter">
                      <NavLink to='/services/taxiservice'><button className="button2">Read More</button></NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 flexcenter" data-aos="fade-left" data-aos-easing="ease-in-sine"
                  data-aos-duration="500">
                  <img width="600px" className="img-fluid" src={taxi} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Tire service */}
        <section>
          <div className="container-fluid p-0 flexcenter">
            <div className="container homecard flexcenter">
              <div className="row w-100">
                <div className="col-md-6 flexcenter" data-aos="fade-right" data-aos-easing="ease-in-sine"
                  data-aos-duration="500">
                  <img width="600px" className="img-fluid" src={tyre} alt="" />
                </div>
                <div className="col-md-6 homecardinside flexcenter" data-aos="fade-left" data-aos-easing="ease-in-sine"
                  data-aos-duration="500">
                  <div className="container text-center">
                    <h1>Tyre Distributor</h1>
                    <p>
                      At Trident, we provide a comprehensive range of tyre to keep your vehicle running smoothly and safely. We offer products from top brands known for their durability and performance, and our competitive pricing ensures you get the best value for your money. Our new tyres will help to keep you on the road safely with confidence.                    </p>
                    <div className="flexcenter">
                      <NavLink to='/services/tyredistributor'><button className="button2">Read More</button></NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </>
  )
}

export default About