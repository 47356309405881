import React,{useEffect} from "react";
import Couroselhome from "../components/Couroselhome";
import taxi from "../assets/abouttaxiimg.png";
import truckabout from "../assets/truckabout.png";
import tyre from "../assets/tyresaleimg.png";
// 
import taxiservice from "../assets/taxiservice.png"
import tireservice from "../assets/tiresservice.png"
import haulageservice from "../assets/truck.png"
// import scrolldownicon from '../assets/scroll-down-icon.png'
import contactmsg from '../assets/sndmsg.png'
import Formmsg from '../components/Formmsg'
import { NavLink, useLocation } from "react-router-dom";
// 
import {Helmet} from 'react-helmet'

function Home() {
  const {pathname}  = useLocation()
    useEffect(()=>{
      window.scrollTo({ top:0, left:0, behavior: "instant" })
  },[pathname])
  // 
  return (
    <>
    <Helmet>
        <title>Trident</title>
        <meta property="og:title" content="Trident" />
        <meta property="og:description" content="Trident offers reliable and innovative haulage, taxi, and tyre solutions with a customer-first approach, ensuring smooth journeys and satisfaction."/>
          {/* <meta property="og:image" content={logoblue}/> */}
          <meta property="og:url" content="https://thetrident.co.uk/"/>
          </Helmet>
      <section className="couroselSection">
        <Couroselhome></Couroselhome>
      </section>
      <section className="topsection flexcenter">
        <div className="container">
          <div className="container-fluid flexcenter p-0">
            <div className="container">
              <h1 className="text-center" data-aos="fade-up" data-aos-easing="ease-in-sine"
                        data-aos-duration="500">Trident</h1>
              <p className="text-justify toppara" data-aos="fade-up" data-aos-easing="ease-in-sine"
                        data-aos-duration="800">
                At Trident, we pride ourselves on offering exceptional haulage, taxi, and tyre services to meet all your transportation and automotive needs. With our commitment to quality and customer satisfaction, you can trust us for timely transportation, reliable taxi rides, and quality tyres.
              </p>
              <div className="w-100 flexcenter mt-5" data-aos="fade-up" data-aos-easing="ease-in-sine"
                        data-aos-duration="1000">
                {/* <button className="btn btn-primary">About US</button> */}
                <NavLink to='/about'>
                  <button class="blob-btn">
                    Read More
                    <span class="blob-btn__inner">
                      <span class="blob-btn__blobs">
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                      </span>
                    </span>
                  </button>
                </NavLink>

              </div>

            </div>
          </div>
          <div className="container-fluid p-0 flexcenter  servicescontainer">
            <div className="container flexcenter">
              <div className="row w-100 text-center">
                <div className="col-md-4">
                  <div className="container" data-aos="fade-up" data-aos-easing="ease-in-sine"
                        data-aos-duration="500">
                    <img width="64px" src={haulageservice} alt="" />
                    <h3>Haulage Services</h3>
                    {/* <p>Our reliable delivery service ensures your orders arrive on time, every time, right at your doorstep.</p> */}
                    <p>Fast and Reliable Transportation Service</p>
                  </div>

                </div>
                <div className="col-md-4">
                  <div className="container" data-aos="fade-up" data-aos-easing="ease-in-sine"
                        data-aos-duration="800">
                    <img width="50px" src={taxiservice} alt="" />

                    <h3>Taxi Services</h3>
                    {/* <p>Our taxi service offers safe, convenient, and timely transportation to your desired destination, available 24/7.</p> */}
                    <p>24/7 Availability & Timely Transportation</p>
                  </div>

                </div>
                <div className="col-md-4">
                  <div className="container" data-aos="fade-up" data-aos-easing="ease-in-sine"
                        data-aos-duration="1000">
                    <img width="50px" src={tireservice} alt="" />
                    <h3>Tyre Distributor</h3>
                    {/* <p>Our tyre service provides expert fitting, balancing, and repair to keep your vehicle running smoothly and safely.</p> */}
                    <p>Quality Brands & Wide Selection</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='scrolldown flexcenter'>
          <div>
            <p className='mb-2'>Scroll Down</p>
            <div className="flexcenter">
              <img width='20px' className='arrowimage' src={scrolldownicon} alt="" />
            </div>
          </div>
        </div> */}
      </section>
      {/* delivery */}


      <div className="homebgcolor">
        <section className="homeaboutsection flexcenter">
          <div className="container-fluid p-0">
            <div className="container">
              <h1 className="headtext text-center" data-aos="fade-up" data-aos-easing="ease-in-sine"
                        data-aos-duration="500">Discover Trident</h1>
              <p className='text-justify mt-5' data-aos="fade-up" data-aos-easing="ease-in-sine"
                        data-aos-duration="800" style={{textAlign:"justify"}}>At Trident, we are committed to delivering exceptional service across our haulage, taxi, and tyre solutions, combining reliability, innovation, and a passion for excellence to ensure every journey is smooth and every customer is satisfied. With a dedicated team of professionals and a customer-first approach, we strive to be your trusted partner in every aspect of your transportation and vehicle care needs.</p>
              <div className="flexcenter" data-aos="fade-up" data-aos-easing="ease-in-sine"
                        data-aos-duration="1000">
                <NavLink to='/about' className="button2" >Read More</NavLink>
              </div>
            </div>
          </div>
        </section>
        <section>
          <h1 className="headtext text-center pt-5" data-aos="fade-up" data-aos-easing="ease-in-sine"
                        data-aos-duration="500">Services We Provide</h1>
          <div className="container-fluid homeservicefluidcntn flexcenter p-0" data-aos="fade-in" data-aos-easing="ease-in-sine"
                        data-aos-duration="500">
            <div className="row cardrowhome" >
              <div className="col-lg-4 flexcenter" data-aos="flip-left" data-aos-easing="ease-in-sine"
                        data-aos-duration="500">
                <div class="card" >
                  <div className="cardimgcontainer flexcenter">
                    <img src={truckabout} class="card-img-top img-fluid" alt="" />
                  </div>

                  <div class="card-body flexcenter">
                    <div>
                      <h4 className="text-center">Haulage Services</h4>
                      <p class="card-text">We prioritize on-time delivery to meet your deadlines, offering reliable and secure haulage solutions, a convenient booking process with flexible options, and advanced tracking systems for real-time updates.</p>
                      <div className="flexcenter">
                        <NavLink to='/services/haulageservices'>
                          <button className="button1">Read More</button>
                        </NavLink>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 flexcenter" data-aos="flip-left" data-aos-easing="ease-in-sine"
                        data-aos-duration="800">
                <div class="card" >
                  <div className="cardimgcontainer flexcenter">
                    <img src={taxi} class="card-img-top img-fluid" alt="" />
                  </div>

                  <div class="card-body flexcenter">
                    <div>
                      <h4 className="text-center">Taxi Services</h4>
                      <p class="card-text">We offer comfortable rides in clean, well-maintained vehicles, driven by professional drivers who prioritize your safety and satisfaction, with 24/7 availability. Enjoy a seamless travel experience with our efficient taxi service.</p>
                      <div className="flexcenter">
                      <NavLink to='/services/taxiservice'>
                          <button className="button1">Read More</button>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 flexcenter" data-aos="flip-left" data-aos-easing="ease-in-sine"
                        data-aos-duration="1000">
                <div class="card" >
                  <div className="cardimgcontainer flexcenter">
                    <img src={tyre} class="card-img-top img-fluid" alt="" />
                  </div>

                  <div class="card-body flexcenter">
                    <div>
                      <h4 className="text-center">Tyre Distributor</h4>
                      <p class="card-text">We offer top-quality tyres from leading brands, including all-season, performance, winter, and off-road options. Our expert team provides tailored advice to help you choose the best tyres for your vehicle, driving habits, and budget.</p>
                      <div className="flexcenter">
                      <NavLink to='/services/tyredistributor'>
                          <button className="button1">Read More</button>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* contact */}
        <section className='contactbtmsection flexcenter'>
          <div className="container-fluid flexcenter p-0">
            <div className="container flexcenter msgcontainer">
              <div className="row w-100">
                <div className="col-md-6 flexcenter" >
                  <img width='500px' src={contactmsg} className='img-fluid' alt="" />
                </div>
                <div className="col-md-6 flexcenter" >
                  <Formmsg></Formmsg>
                </div>
              </div>
            </div>
          </div>

        </section>
      </div>


    </>
  );
}

export default Home;
