import React, { useState } from 'react'
// import axios from 'axios'
import { useFormik } from "formik";
import { formSchema } from '../validationSchema'
// import { useNavigate } from 'react-router-dom';
import { succesToaster, errorToaster } from './toaster';
// import Email from './smtp';
import emailjs from '@emailjs/browser';

function Formmsg() {
  // console.log(process.env.REACT_APP_SECRET_KEY,"key")
  const [isloading, setisloading] = useState(false)
  const formik = useFormik({
    initialValues: {
      "name": "",
      "email": "",
      "phone": "",
      "message": ""
    },
    // process.env.REACT_APP_SECRET_KEY
    onSubmit: () => {
      const template_id = 'template_bj92h9s'
      const service_id = 'service_vsqmdhl'
      const public_key = 'BVcvWvz2JLHVxtsTb'
      // params
      const template_params = {
        name: formik.values.name,
        email: formik.values.email,
        phone: formik.values.phone,
        message: formik.values.message,
      }
      setisloading(true)
     emailjs.send(service_id,template_id,template_params,public_key,).then((response)=>{
      console.log(response)
      setisloading(false)
      formik.resetForm()
      succesToaster('success',`${formik.values.name}, thank you for getting in touch`)
     }).catch((err)=>{
      console.log(err)
      setisloading(false)
      errorToaster('error','Message not sent')
      formik.resetForm()
     })

      // setisloading(true)
      // axios.post('http://localhost:8000/contactus', formik.values,{
      //   headers : {
      //     api_key : process.env.REACT_APP_SECRET_KEY
      //   }
      //   // 'YxkbcXOSirMsNHvGZCoa' 
      // }).then((response) => {
      //   succesToaster('success',`${formik.values.name}, thank you for getting in touch`)
      //   formik.resetForm()
      //   setisloading(false)
      // }).catch((error) => {
      //   console.log(error)
      //   errorToaster('error','Message not sent')
      //   setisloading(false)
      // })
//       Email.send({
//         Host: "smtp.elasticemail.com",
//         Username: "tridenttransportsandlogistics@gmail.com",
//         Password: "EB480EFB542343AB79545259E0F92F34C562",
//         To: "ashiklawrence12@gmail.com",
//         From: "tridenttransportsandlogistics@gmail.com",
//         Subject: "New Enquiry",
//         Body: `
//         <head>
//     <style>
//         body {
//             font-family: Arial, sans-serif;
//             background-color: #f2f2f2;
//         }

//         .container {
//             max-width: 600px;
//             margin: 0 auto;
//             padding: 20px;
//             background-color: #fff;
//             border-radius: 5px;
//             box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
//         }

//         .header {
//             text-align: center;
//             margin-bottom: 20px;
//         }

//         .header h1 {
//             color: #333;
//             font-size: 24px;
//             margin: 0;
//         }

//         .content {
//             margin-bottom: 30px;
//         }

//         .content p {
//             margin: 0 0 10px;
//             line-height: 1.5;
//         }

//         .footer {
//             text-align: center;
//         }

//         .footer p {
//             color: #999;
//             font-size: 14px;
//             margin: 0;
//         }
//     </style>
// </head>
// <body>

//       <div class="container">
//       <div class="header">
//           <h1>New Enquiry</h1>
//       </div>
//       <div class="content">
//           <p>Hi,</p>
//           <p>A new enquiry from ${formik.values.name}</p>
//           <p>Message : ${formik.values.message}</p>
//           <p>Contact Details</p>
//           <ul>
//               <li>Mobile : ${formik.values.phone}</li>
//               <li>Email : ${formik.values.email}</li>
//           </ul>
//       </div>
//       <div class="footer">
//           <p>Best regards,</p>
//           <p>The Team</p>
//       </div>
//   </div>
//     </body>`,
//       }).then(
//         (response) => {
//           console.log(response)
//           setisloading(false)
//           formik.resetForm()
//           succesToaster('success', `${formik.values.name}, thank you for getting in touch`)
//         }
//       ).catch((err) => {
//         console.log(err)
//         setisloading(false)
//         formik.resetForm()
//         errorToaster('error', 'Message not sent')
//       });

    },

    validationSchema: formSchema
  })
  console.log(formik.errors)
  return (
    <>
      <div className="container flexcenter">

        <div className="container formcontainer">
          <h1 className='text-center'>Send Us Message</h1>
          <form className='forminnercontainer' onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Name <span style={{color:'red',fontSize:'24px'}}>*</span></label>
              <input type="text" name="name" className={formik.errors.name && formik.touched.name ? "form-control is-invalid" : "form-control"} value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter your name" />
              {/* validation message*/}
              {formik.errors.name && formik.touched.name ? <p className='invalid-feedback'>{formik.errors.name}</p> : null}
            </div>


            <div className="emailinput mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Email address<span style={{color:'red',fontSize:'24px'}}>*</span></label>
              <input type="text" name="email" className={formik.errors.email && formik.touched.email ? "form-control is-invalid" : "form-control"} value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter email address" />
              {/* validation message*/}
              {formik.errors.email && formik.touched.email ? <p className='invalid-feedback'>{formik.errors.email}</p> : null}
            </div>
            <div className="phoneinput mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Phone<span style={{color:'red',fontSize:'24px'}}>*</span></label>
              <input type="text" name="phone" className={formik.errors.phone && formik.touched.phone ? "form-control is-invalid" : "form-control"} value={formik.values.phone} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter phone number" />
              {/* validation message*/}
              {formik.errors.phone && formik.touched.phone ? <p className='invalid-feedback'>{formik.errors.phone}</p> : null}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label"> Message<span style={{color:'red',fontSize:'24px'}}>*</span></label>
              <textarea type="text" rows="4"
                cols="100" name="message" className={formik.errors.message && formik.touched.message ? "form-control is-invalid" : "form-control"} value={formik.values.message} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter message" />
              {/* validation message*/}
              {formik.errors.message && formik.touched.message ? <p className='invalid-feedback'>{formik.errors.message}</p> : null}
            </div>

            {/* {formik.isValid ? null : <span className="text-danger">Errors in some field</span> } */}
            <div class="container flexcenter">
              <button
                type="submit"
                className={!(formik.isValid && formik.dirty) ? "btn button2 disabled mt-4":"btn button2  mt-4 "}
                // className="button2 mt-4"
                disabled={!(formik.isValid && formik.dirty)}
              >
                Submit
              </button>
            </div>
          </form>
          {isloading ? <div className="loadercontain flexcenter">
            <div className="loader">

            </div>
          </div> : null}

        </div>


      </div>
    </>
  );
}

export default Formmsg;
